/**
 * @name: index
 * @author: Gzm
 * @date: 2023-06-01 15:56
 * @description：规则
 * @update: 2023-06-01 15:56
 */
import {get, postJ} from "@/request";
import {IWalletRules, IWalletRulesList} from "@/apis/marketingManage/rules/types";
import {ICoachByPage} from "@/apis/finance/coachReconcile/types";
import {IPage, IPageRes} from "@/apis/page";
/**
 * 查询详情
 */
export const dictDetailApi = (types:number)=> get("/golf/dict/detail", {types})

/**
 * setUp
 */
export const dictSetUpApi = (param:any)=> get("/golf/dict/setUp", param)

/**
 * 创建钱包规则
 */
export const userWalletSetupCreateApi = (param:IWalletRules)=> postJ("/golf/userWalletSetup/create", param)

/**
 * 钱包充值规则分页查询
 */
export const queryCoachSettlementByPageApi = (param:IPage)=> get<IPageRes<IWalletRulesList[]>>("/golf/userWalletSetup/query", param)

/**
 * 钱包充值规则删除
 */
export const userWalletSetupRemoveApi = (id:string)=> get(`/golf/userWalletSetup/remove/${id}`)
