
import {Component, Vue} from "vue-property-decorator";
import {dictDetailApi, dictSetUpApi} from "@/apis/marketingManage/rules";
import {Message} from "element-ui";

/**
 * @name: rules
 * @author: Gzm
 * @date: 2023-06-01 15:28
 * @description：营销管理-平台规则
 * @update: 2023-06-01 15:28
 */

@Component({})
export default class UserIndex extends Vue {
  activeName: string = "first"

  from: any = {
    yaun: null,
    integral: null,
    orderRules: null,
    payTime:null
  }

  getList(types: number) {
    if (types == 1) {
      dictDetailApi(types).then(e => {
        this.from.yaun = e.value || 0
      })
    } else if (types == 2) {
      dictDetailApi(types).then(e => {
        this.from.orderRules = e.value || 0
      })
    } else if (types == 3) {
      dictDetailApi(types).then(e => {
        this.from.integral = e.value || 0
      })
    } else if (types ==4) {
      dictDetailApi(types).then(e => {
        this.from.payTime = e.value || 0
      })
    }
  }

  handleTime(val: any) {
    let mm = parseInt(val)
    //@ts-ignore
    let h = parseInt(mm / 60)
    //@ts-ignore
    let m = parseInt(mm % 60)
    this.from.orderRules = h.toString().padStart(2, "0") + ':' + m.toString().padStart(2, "0")
  }

  handleBlur() {
    if (this.from.yaun <= 0 || !this.from.yaun || this.from.yaun == "") {
      this.from.yaun = 1
      dictSetUpApi({
        types: 1,
        values: 1
      })
      this.getList(1)
      return Message.error('金额不能小于等于0')
    } else {
      dictSetUpApi({
        types: 1,
        values: this.from.yaun
      }).then(e => {
        Message.success('设置成功')
        this.getList(1)
      })
    }
  }

  handleBlurInteg() {
    if (!this.from.integral || this.from.integral == "") {
      this.from.integral = 1
      dictSetUpApi({
        types: 3,
        values: 1
      })
      this.getList(3)
      return Message.error('积分不能为空')
    } else {
      dictSetUpApi({
        types: 3,
        values: this.from.integral
      }).then(e => {
        Message.success('设置成功')
        this.getList(3)
      })
    }
  }

  handleChange() {
    dictSetUpApi({
      types: 2,
      values: this.from.orderRules || 0
    }).then(e => {
      Message.success('设置成功')
      this.getList(2)
    })
  }

  handleChangeTime() {
    if (this.from.payTime == 0){
      this.getList(4)
      return Message.error('不能为0')
    }
    if (this.from.payTime > 999){
      this.getList(4)
      return Message.error('最大为3位数')
    }
    dictSetUpApi({
      types: 4,
      values: this.from.payTime || 0
    }).then(e => {
      Message.success('设置成功')
      this.getList(4)
    })
  }

  created() {
    this.getList(1)
    this.getList(2)
    this.getList(3)
    this.getList(4)
  }

}
